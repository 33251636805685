import React, { useState } from "react"
import { connect } from "react-redux"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"

import { lingua_it } from "../i18n/it-IT"
import { lingua_en } from "../i18n/en-GB"
import { lingua_es } from "../i18n/es-ES"
import { lingua_ca } from "../i18n/es-CA"

import { FaFacebookF, FaInstagram } from "react-icons/fa"

import Seo from "../components/seo"

const IndexPage = props => {
  const { data, lang } = props

  let lingua = {}

  switch (lang) {
    case "it-IT":
      lingua = lingua_it
      break
    case "es-CA":
      lingua = lingua_ca
      break
    case "es-ES":
      lingua = lingua_es
      break
    case "en-GB":
      lingua = lingua_en
      break
    default:
      lingua = lingua_it
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  return (
    <div>
      <Seo
        title={lingua.sito.titolo}
        keywords={[
          `3D Shirt`,
          `configuratore 3d per camicie`,
          `camicie su misura`,
        ]}
        description={lingua.sito.sottotitolo}
      />
      <section
        style={{
          background: "url(" + data.hero2.childImageSharp.fluid.src + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
        className="hero is-large"
      >
        <div className="hero-body container">
          <div className="columns">
            <div className="column">
              <div className="box" style={{ background: "rgba(23,23,23,.3)" }}>
                <h1 className="title is-size-3-mobile is-2 is-spaced has-text-light">
                  {lingua.sito.titolo}
                </h1>
                <h2 className="subtitle is-5 has-text-light">
                  {lingua.sito.sottotitolo}
                </h2>
                <div className="buttons">
                  <Link
                    to="/tessuto00011"
                    className="ls-button button has-text-weight-bold is-primary is-medium is-radiusless"
                  >
                    {lingua.sito.bottone1}
                  </Link>
                </div>
              </div>
            </div>
            <div className="column"></div>
          </div>
        </div>
      </section>

      <div className="section is-medium">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <div className="ls-section-content has-text-centered ls-space-bottom">
                <h3 className="title">{lingua.sito.titolo2a}</h3>
                <p
                  className="content  is-size-5"
                  style={{ marginBottom: "3rem" }}
                >
                  {lingua.sito.sottotitolo2a}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container has-text-centered">
          <div className="columns is-centered">
            <div className="column is-5 is-4-desktop">
              <form
                method="post"
                action="https://formspree.io/luigi.defrancesco@globalapulia.com"
              >
                <div className="field">
                  <div className="control">
                    <input
                      className="input is-medium"
                      name="_replyto"
                      value={email}
                      placeholder={lingua.contact.email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-primary is-medium is-fullwidth">
                    {lingua.sito.iscriviti}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="buttons is-centered" style={{ marginTop: "4rem" }}>
            <a
              href="https://www.facebook.com/sartorieitaliane"
              className="button is-white is-large"
            >
              <FaFacebookF
                style={{ width: "2rem", height: "2rem", color: "#587895" }}
              />
            </a>
            <a
              href="https://www.instagram.com/sartorieitaliane.it/"
              className="button is-white is-large"
            >
              <FaInstagram
                style={{ width: "2rem", height: "2rem", color: "#587895" }}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="section is-medium" style={{ background: "#f0f5f6" }}>
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="column is-8-desktop is-offset-2-desktop">
                <div className="ls-section-content has-text-centered ls-space-bottom">
                  <h3 className="title">{lingua.sito.titolo2}</h3>
                  <p
                    className="content  is-size-5"
                    style={{ marginBottom: "3rem" }}
                  >
                    {lingua.sito.sottotitolo2}
                  </p>
                </div>
              </div>
            </div>

            <div
              className="columns is-desktop"
              style={{ marginBottom: "3.25rem" }}
            >
              <div
                className="column is-full is-one-third-desktop"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="box"
                  style={{
                    height: "100%",
                    borderRadius: "0px",
                    padding: "22px 34px",
                    boxShadow: "0 2px 12px rgba(20,20,20,.14)",
                  }}
                >
                  <div className="media">
                    <span className="media-left" style={{ width: "100px" }}>
                      <Img fluid={data.service1.childImageSharp.fluid} />
                    </span>
                    <div className="media-content">
                      <h4
                        className="title is-size-6 is-size-4-widescreen"
                        style={{ marginBottom: "0.6rem" }}
                      >
                        {lingua.sito.titolo3}
                      </h4>
                      <p>{lingua.sito.sottotitolo3}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="column is-full is-one-third-desktop"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="box"
                  style={{
                    height: "100%",
                    borderRadius: "0px",
                    padding: "22px 34px",
                    boxShadow: "0 2px 12px rgba(20,20,20,.14)",
                  }}
                >
                  <div className="media">
                    <span className="media-left" style={{ width: "100px" }}>
                      <Img fluid={data.service2.childImageSharp.fluid} />
                    </span>
                    <div className="media-content">
                      <h4
                        className="title is-size-6 is-size-4-widescreen"
                        style={{ marginBottom: "0.6rem" }}
                      >
                        {lingua.sito.titolo5}
                      </h4>
                      <p>{lingua.sito.sottotitolo5}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="column is-full is-one-third-desktop"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="box"
                  style={{
                    height: "100%",
                    borderRadius: "0px",
                    padding: "22px 34px",
                    boxShadow: "0 2px 12px rgba(20,20,20,.14)",
                  }}
                >
                  <div className="media">
                    <span className="media-left" style={{ width: "100px" }}>
                      <Img fluid={data.service3.childImageSharp.fluid} />
                    </span>
                    <div className="media-content">
                      <h4
                        className="title is-size-6 is-size-4-widescreen"
                        style={{ marginBottom: "0.6rem" }}
                      >
                        {lingua.sito.titolo7}
                      </h4>
                      <p>{lingua.sito.sottotitolo7}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              <Link
                to="/tessuto00011/"
                className="ls-button button has-text-weight-bold is-dark is-medium is-radiusless"
              >
                {lingua.sito.bottone1}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium">
        <div>
          <div className="container">
            <div
              className="columns is-desktop"
              style={{ marginBottom: "3.25rem" }}
            >
              <div
                className="column is-full is-3-desktop"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem 4rem",
                }}
              >
                <Img fluid={data.logo1.childImageSharp.fluid} />
              </div>
              <div
                className="column is-full is-3-desktop"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem 4rem",
                }}
              >
                <Img fluid={data.logo2.childImageSharp.fluid} />
              </div>
              <div
                className="column is-full is-3-desktop"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem 4rem",
                }}
              >
                <Img fluid={data.logo3.childImageSharp.fluid} />
              </div>
              <div
                className="column is-full is-3-desktop"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "1rem 4rem",
                }}
              >
                <Img fluid={data.logo4.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="section is-medium has-text-light"
        style={{
          background: "url(" + data.hero.childImageSharp.fluid.src + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="container">
            <div className="columns">
              <div className="column"></div>
              <div className="column">
                <div
                  className="box"
                  style={{ background: "rgba(23,23,23,.3)" }}
                >
                  <h1 className="title is-size-3-mobile is-2 is-spaced has-text-light">
                    {lingua.sito.titolo8}
                  </h1>
                  <h2 className="subtitle is-5 has-text-light">
                    {lingua.sito.sottotitolo8}
                  </h2>
                  <div className="buttons">
                    <Link
                      to="/tessuto00011"
                      className="ls-button button has-text-weight-bold is-primary is-medium is-radiusless"
                    >
                      {lingua.sito.bottone1}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    lang: state.shop.lingua,
  }
}

export default connect(mapStateToProps)(IndexPage)

export const query = graphql`
  query {
    sfondo: file(relativePath: { regex: "/sito/sfondo.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero: file(relativePath: { regex: "/sito/hero.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hero2: file(relativePath: { regex: "/sito/hero2.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1980) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo1: file(relativePath: { regex: "/sito/logo-tessuti-01.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo2: file(relativePath: { regex: "/sito/logo-tessuti-02.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo3: file(relativePath: { regex: "/sito/logo-tessuti-05.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    logo4: file(relativePath: { regex: "/sito/logo-tessuti-03.jpg/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service3: file(relativePath: { regex: "/sito/home-01-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service1: file(relativePath: { regex: "/sito/home-02-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    service2: file(relativePath: { regex: "/sito/home-03-100x100.png/" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
